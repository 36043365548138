import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getCurrentUserId } from '../store/storeValtio';
import Footer from '../navigation/Footer';
import Home from './Home';
import TryIt from './TryIt';

export default function MainScreen({page}) {
  //const params = useParams();
  //const page = params.page;
  const currentUserId = getCurrentUserId();
  console.log('MainScreen', currentUserId);
  const navigate = useNavigate();

  console.log('MainScreen', page, typeof(page));
  // useEffect(() => {
  //   if (currentUserId) navigate('/meds');
  // });

  return (
    <div id='mcard'>
      <div className='lcard'>
        {(!page || page === 1) &&
        <Home />
        }
        {page === 2 &&
          <TryIt />
        }
      </div>
      <Footer />
    </div>
  );
}
