import React, { useEffect } from 'react'


export default function TryIt() {

  function copyToClipboard() {
    navigator.clipboard.writeText('https://app.itookitwhen.com');
    alert('Copied');
  }
  function goToLink() {
    document.getElementById('goToLink').click();
  }
  
  
  return (
    <div>
        <div className='flex justify-center text-center font-bold text-3xl w-full px-5 pt-6'>
            Get the ITookItWhen App
        </div>
        <div className='flex flex-col justify-centerw-full px-5 pt-6'>
            <div className='font-bold text-2xl text-center'>
                <p>
                The ITookItWhen app is a Progressive Web Application (PWA)
                </p>
            </div>
            <div className='font-semibold text-xl text-left '>
                <p className='mt-5'>
                A PWA is a type of webpage or website known as a web application. A such, it is able to run as a normal website, 
                but it is also able to be installed on a mobile device or desktop as if it were a stand-alone application. 
                ITookItWhen (ITIW) is intended to be installed so that it can run whether or not there is an internet connection. 
                </p>
                <p className='mt-2'>
                All data used by the app are stored on the local device so use is not dependent on any external system in order to function.
                This means that the data used by the application is available ONLY on the device on which the app is installed (unless optional advanced sharing features are enabled).
                </p>
                <p className='mt-4'>
                To try the app, all you need to do is to go to the web address below using the browser (either Chrome or Edge) on the device you wish to use.
                Usually you would use a mobile device such as a cell phone or tablet, but the app will run on a desktop also.
                For installation, you need access to the internet, either through your mobile provider or using a WiFi connection.
                Remember that the data that you enter into the app is only stored on the device on which the app is installed.
                </p>
            </div>
            <div className='mt-6 w-full'>
              <div className='flex flex-row justify-center w-full font-bold text-2xl'>
                <div className='p-2 bg-slate-300 shadow-md rounded-md'>https://app.itookitwhen.com</div>
                <div className='ml-4 p-2 bg-slate-300 shadow-md rounded-md hover:cursor-pointer hover:bg-slate-500' onClick={()=>copyToClipboard()}>Copy link</div>
                <div className='ml-4 p-2 bg-slate-300 shadow-md rounded-md hover:cursor-pointer hover:bg-slate-500' onClick={()=>goToLink()}>Go to link</div>
              </div>
            </div>
            <div className='font-semibold text-xl'>
                <p className='mt-5'>
                  
                </p>
            </div>
        </div>
        {/* below is a 'hidden' link, at least one that is very difficult to find */}
        <a id='goToLink' name='goToLink' href='https://app.itookitwhen.com' target='_blank' rel='noreferrer'> </a>
    </div>
  )
}

