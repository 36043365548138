import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as mh from '../../common/medicationHelpers.js';
import * as gh from '../../common/generalHelpers.js';
import moment from 'moment';

export default function MedicationListItem({ med, latestHist }) {
  //console.log('MedicationListItem', med.medicationId);
  //console.log('MedicationListItem');
  //console.log('MedicationListItem med', med);
  //console.log('MedicationListItem hist', latestHist);
  const navigate = useNavigate();

  let lt = '';
  let ltAgo = 'Never taken';
  let isDue = false;
  if (!med.isPaused)
  {
    const nextDue = mh.nextDueDateTime(med);
    const now = moment();
    if (nextDue) {
      //console.log('nextDue', med.name, nextDue);
      const nd = moment(nextDue);
      //isDue = now.isAfter(nd);
      isDue = now.isSameOrAfter(nd);
      //console.log('isDue', med.name, now, nd, isDue);
    }
  }

  if (latestHist) {
    lt = latestHist.status === 'taken' ? gh.formatTime(latestHist.date) : '';
    ltAgo = mh.formatLastTaken(latestHist);
  }

  function goTo(target) {
    //history.back();
    navigate(target);
    return;
  }

  let medColor = 'text-blue-500';
  switch (med.type)
  {
    case 'P':
      medColor = 'text-pink-500';
      break;
    case 'S':
      medColor = 'text-green-500';
      break;
    case 'C':
      medColor = 'text-yellow-500';
      break;
    default:
      break;
  }

  medColor += ' text-2xl';

  return (
    <div className='mcard border-2 border-gray-200 mb-1'>
      <div className='mcard-header flex'>
        <span className={medColor}>{med.name}</span>
        {isDue && (
          <span className='text-red-600 text-xl font-bold float-right'>
            DUE
          </span>
        )}
        {med.isPaused && (
          <span className='text-red-900 text-lg font-semibold float-right'>
            Paused
          </span>
        )}
      </div>
      <div className='mrow'>
        <div className='w-8/12'>
          <div className='m-2 font-bold text-base small:text-lg phone:text-xl tablet:text-2xl'>
            {ltAgo}
            <br />
            {lt}
          </div>
          <div className='flex justify-between pb-1'>
            <button onClick={()=>goTo(`/meds/${med.medicationId}`)} className='mbtn w-5/12 mr-2 bg-yellow-500'>Details</button>
            <button onClick={()=>goTo(`/history/${med.medicationId}`)} className='mbtn w-5/12 bg-yellow-500'>History</button>
          </div>
        </div>
        <div className='mcol inline-flex w-full justify-end'>
          {!med.isPaused && (
              <button onClick={()=>goTo(`/take/${med.medicationId}/medications`)} className='mbtn w-full m-1 text-3xl text-white bg-green-600'>Take</button>
          )}
        </div>
      </div>
    </div>
  );
}
